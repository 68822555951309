<template>
  <div>
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="详情" name="first">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_01.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_02.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_03.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_04.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_05.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_06.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_07.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_08.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_09.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_10.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_11.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_12.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_13.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_14.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_15.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_16.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_17.jpg" lazy width="100%" height="100%">
        <img src="../../../../../public/images/相机详情/室外摄像机（500万像素）/Z61Q室外高清机/Z61Q_18.jpg" lazy width="100%" height="100%">

      </el-tab-pane>
      <el-tab-pane label="规格" name="second">
        <table width="60%" border="1" cellpadding="10" cellspacing="0" bordercolor="#000000" style="border-collapse:collapse;">
          <tr>
            <td>类别</td>
            <td>类型</td>
            <td>参数</td>
          </tr>
        </table>
      </el-tab-pane>
      <el-tab-pane label="视频" name="third">
        <video src="../../../../../public/images/三级页面/视频样例/此视频借用到产品CS48Q视频.mp4"  width="100%" controls="controls"></video>
      </el-tab-pane>
      <el-tab-pane label="技术支持" name="fourth">

      </el-tab-pane>
      <el-tab-pane label="扩展" name="kz">

      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "Z61Q",
  data() {
    return {
      activeName: 'first'
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style scoped>
.tabs{
  margin-top: 100px;
}
/deep/.el-tabs__item{
  font-size: 16px;
}
</style>